import React, { useState } from "react";
import { AddItemInput, AddButton, InputButtonContainer, ToDoLiItems, ToDoListLi, ToDoListUl } from ".";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";

const TodoListLnput = ({ toDo, setToDo }) => {
  const [text, setText] = useState("");
  const [check, setCheck] = useState("");

  const onChange = (e) => {
    // console.log(`checked = ${e.target.checked}`);
    console.log(e.target.checked);
    setCheck(e.target.checked);
  };

  const handleDelete = (index) => {
    const newTodos = [...toDo];
    newTodos.splice(index, 1);
    setToDo(newTodos);
  };

  return (
    <>
      <InputButtonContainer>
        <AddItemInput value={text} type="text" onChange={(e) => setText(e.target.value)} placeholder="Add a new task" />
        <AddButton
          type="submit"
          onClick={() => {
            if (text !== "") {
              const ToDo = [...toDo, text];
              setToDo(ToDo);
              setText("");
            }
          }}
        >
          <PlusOutlined />
        </AddButton>
      </InputButtonContainer>
      <ToDoListUl>
        {Array.isArray(toDo) &&
          toDo.map((done, index) => {
            return (
              <ToDoListLi key={index}>
                <ToDoLiItems>
                  <Checkbox onChange={onChange}>{done}</Checkbox>

                  <DeleteOutlined onClick={() => handleDelete(index)} />
                </ToDoLiItems>
              </ToDoListLi>
            );
          })}
      </ToDoListUl>
    </>
  );
};

export default TodoListLnput;
