import React from "react";
import moment from "moment";
import { DateStyle } from ".";

const Date = () => {
  const date = moment().format("MMMM / DD / YYYY");
  return (
    <div>
      <DateStyle style={{ marginTop: "0px" }}>{date}</DateStyle>
    </div>
  );
};

export default Date;
