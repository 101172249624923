import styled from "styled-components";

export const AddItemInput = styled.input`
  width: 350px;
  height: 40px;
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  padding: 0px 8px;
`;

export const AddButton = styled.button`
  height: 40px;
  width: 40px;
  background-color: #d4a373;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: larger;
  margin-left: 5px;
`;

export const TopHr = styled.hr`
  background: #dadada;
  border: none;
  height: 1px;
  width: 450px;
  margin-bottom: 15px;
`;

export const InputButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
`;

export const ToDoLiItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 4px;
  margin-bottom: 13px;
  justify-content: space-between;
  padding-right: 43px;
`;

export const ToDoListLi = styled.li`
  list-style: none;
`;

export const ToDoListUl = styled.ul`
  overflow-y: auto;
  max-height: 265px;
`;
