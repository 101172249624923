import styled from "styled-components";

export const ToDoListBody = styled.div`
  width: 500px;
  height: 450px;
  border: 1px solid white;
  margin: auto;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 0px 20px #ffd1a4a8;
`;

export const ToDoListTopItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 26px;
`;

export const ToDoListTopItemsRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const TopTextDate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  p {
    margin-bottom: 5px;
    margin-top: 0px;
    font-weight: 600;
  }
`;
