import React, { useState } from "react";
import { ToDoListBody, ToDoListTopItems, ToDoListTopItemsRight, TopTextDate, TopHr } from ".";
import { CalendarOutlined } from "@ant-design/icons";
import Date from "./date";
import TodoListLnput from "./todo.list.input";

const TodoList = () => {
  const [toDo, setToDo] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <ToDoListBody>
      <form onSubmit={handleSubmit}>
        <ToDoListTopItems>
          <h1>Todo</h1>
          <ToDoListTopItemsRight>
            <TopTextDate>
              <p>Today</p>
              <Date />
            </TopTextDate>

            <CalendarOutlined style={{ fontSize: "xx-large" }} />
          </ToDoListTopItemsRight>
        </ToDoListTopItems>
        <TopHr />
        <TodoListLnput toDo={toDo} setToDo={setToDo} />
      </form>
    </ToDoListBody>
  );
};

export default TodoList;
